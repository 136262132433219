@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap);
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    scroll-behavior: smooth
}

.pbor {
    border-right: 2px solid lightgray;
}

.table-show-data {
    word-break: break-word;
}

.disp {
    display: none;
}

.nav-item {
    border: 1px solid #eaeaea;
    font-size: 12px;
}

.dropdown {
    padding-left: 20px;
}

.dispp {
    display: block;
}

.csvConvBox {
    border: 1px solid lightgrey;
    padding: 20px;
}

.csvConvBox button {
    margin: 10px 0px !important;
}

@media (max-width: 600px) {
    .disp {
        display: block;
    }

    .dispp {
        display: none;
    }

    .font {
        font-size: 19px !important;
    }

    .mrt {
        margin-top: 12px !important;
    }

    .nav-link {
        padding: 0% !important;
    }

    .wd {
        width: 100% !important;
    }

    .vv {
        display: flex !important;
        justify-content: center;
        align-self: center;
        flex: 1 1;
        flex-direction: column;
    }

    .coursedetailh2 {
        font-size: 1rem;
        font-weight: 900;
    }

    .padding {
        padding-right: 0% !important;
    }

    .pbor {
        border-right: none !important;
    }

    .form-jumbotron h3 {
        color: #333;
        position: absolute;
        top: 18% !important;
        font-weight: 700 !important;
        left: 18% !important;
        font-size: 19px !important;

    }

    .jumbotron {
        /* background-image: url('/src/pics/jbt.PNG') ;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
    min-height: 700px;*/
        background: url(/static/media/header.d66ff785.png) no-repeat 100% !important;

        background-size: 100% 100 !important;
        min-height: 700px;
    }

    .co-button {
        width: 100% !important;
        color: white !important;
        background: #47a547 !important;
        height: 50px !important;
        border: 1px solid #47a547;
    }

    .splash2 {
        height: 100% !important;
        padding: 15px;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.text-color a {
    color: white !important;
    margin-left: 60px;
}

.copyright {
    font-size: 14px !important;
}

.footer-text a {
    color: white;
    font-size: 14px;
    background-color: "#1f6f8b";
    margin: 10px;
}

.footer-text {
    text-align: end;
}

#footer {
    /* position: absolute; */
    bottom: 0;
    width: 100%;
}

.footer-container {
    /*min-height: 31.7vh;  will cover the 100% of viewport */
    overflow: hidden;
    display: block;
    position: relative;
    /* padding-bottom: 52px; */
}

.footer-text a:hover {
    color: orange !important;
    font-size: 14px;
    text-decoration: none;
}

.about {
    background-color: #e7e6e1;
}

span.MuiButtonBase-root.MuiIconButton-root.jss14.MuiCheckbox-root.MuiCheckbox-colorSecondary.MuiIconButton-colorSecondary {
    min-width: 40px;
    margin: auto;
}

.card-header {
    cursor: pointer;
}

.pointer-cursor {
    cursor: pointer;
}

#header a {
    font-size: 14px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    color: black;
    z-index: 5;
}

#header {
    background-color: white;
    height: 100px;
}

#header a:hover {
    color: orange;
}

.jumbotron {
    /*background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKjGjdqA0KJSaRCoC8ImZvF5l05gTN4bu6hA&usqp=CAU");*/
    background-size: cover;
    color: white;
    font-size: large;
    font-weight: bold;
}

#video-background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: 1;
    width: 100%;
}

.jumbotron {
    position: relative;
    overflow: hidden;
}

.jumbotron .container {
    position: relative;
    z-index: 1;
}

#carouselCap {
    color: black;
    margin: auto;
    font-size: 40px;
    font-weight: bold;
    padding: 170px;
    text-align: left;
}

.videosect {
    height: 400px;
}

.wrapper {
    text-align: center;
}

* {
    max-width: 100%;
}

.topBar {
    height: 20px;
}

#footer .row {
    margin: auto;
    width: 1250px;
}

img {
    max-width: 100%;
    max-height: 100%;
}

.about .row {
    margin-left: 0px;
    margin-right: 0px;
}

.about .col-md-6 {
    padding-left: 0px;
    padding-right: 0px;
}

.dropdown .btn-primary {
    background-color: white;
    color: black;
    border-color: white;
    border-bottom-color: gray;
    width: 100%;
}

.dropdown .btn-primary:hover {
    background-color: white;
    color: black;
    border-bottom-color: darkgrey;
    width: 100%;
}

.dropdown .btn-primary:current {
    background-color: white;
    color: black;
    border-bottom-color: darkgrey;
}

.required:after {
    content: " *";
    color: red;
}

.required {
    font-weight: bold;
}

.pBold {
    font-weight: bold;
}

label.required {
    padding: 0px;
}

label {
    padding: 0px;
}

#icon.MuiSvgIcon-root {
    fill: black;
    cursor: pointer;
}

canvas.sigCanvas {
    background-color: rgb(233, 224, 224);
    margin: 20px 20px 20px 70px;
}

canvas.sigCanvas.nomargin {
    margin: 0px;
}

.college-terms-small-box {
    height: 300px;
    overflow: auto;
    margin: 20px;
    border: 1px solid #eaeaea;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0px 2px 10px 0px rgb(211 211 211 / 75%)
}

.MuiCardMedia-root {
    background-size: auto;
    padding-top: 60%;
}



@media (min-width: 576px) {
    .modal-dialog {
        max-width: 800px !important;
    }
}


@media only screen and (max-width: 768px) {
    #responsive {
        width: 100%;
    }

    #responsive-navbar-nav {
        background: rgb(255 255 255) !important;
        z-index: 5;
        position: absolute;
        top: 80px;
        padding: 20px;
        border: 1px solid #eaeaea;
        right: 40px;
    }

    #details {
        margin: -10px;
    }

    #pBar {
        margin-top: 50px;
    }

    #sigBtn {
        margin: 10px;
    }

    canvas.sigCanvas {
        margin: 0px;
    }

    #btns {
        margin: 5px;
    }

    #checkStmts {
        display: flex;
        align-items: baseline;
        flex-flow: nowrap;
    }
}

img.card-img-top {
    background-size: auto;
    height: 100%;
    width: 100%;
    border-radius: 0px;
    margin: 0px;
}

.popup-content {
    margin: auto;
    background: wheat;
    width: 50%;
    padding: 5px;
}

#btns {
    margin: 5px;
}

#btn1 {
    visibility: hidden;
}

input[type="date"]::before {
    color: #999999;
    content: attr(placeholder);
}

input[type="date"] {
    color: #ffffff;
}

input[type="date"]:focus,
input[type="date"]:valid {
    color: #666666;
}

input[type="date"]:focus::before,
input[type="date"]:valid::before {
    content: "" !important;
}

input[type="checkbox"] {
    vertical-align: middle;
    margin-right: 8px;
}

.container6 {
    height: 17em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container6 h2 {
    margin: 0;
}

#root>main>div>div>div>form>button:nth-child(14) {
    background-color: green;
    border-color: green;
}

#uncontrolled-tab-example-tabpane-home>p:nth-child(1) {
    visibility: hidden;
}

p.card-text {
    background-color: #09d1f1;
    padding: 5px;
    width: 114px;
    color: #fff;
    border-radius: 3px;
    text-align: center;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0px;
    margin-left: 0px;
}

.card-title {
    font-size: 16px;
    font-weight: 600;
}

.card-body .desc {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    -webkit-box-orient: vertical;
}

#header-inside {
    width: 100%;
    max-width: 1280px;
    padding-left: 24px;
    padding-right: 24px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar-nav {
    padding-left: 50px !important;
}

#header {
    height: 100px;
    width: 100%;
    margin: auto;
    border-bottom: 1px solid #eaeaea;
}

#header a {
    font-size: 14px;
    font-weight: 400;
    font-family: Arial, Helvetica, sans-serif;
    text-transform: capitalize !important;
}

#header a:hover {
    color: #008ab3;
}

.jumbotron {
    /* background-image: url('/src/pics/jbt.PNG') ;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
  min-height: 700px;*/
    background: url(/static/media/header.d66ff785.png) no-repeat center center;
    background-size: 100% 100%;
    min-height: 700px;
}

.card {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 130px 0 rgb(62 57 107 / 12%), 0 0 20px 0 rgb(62 57 107 / 12%);
    padding: 25px;
    margin: 10px 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    transition: 0.35s;
    position: relative;
    overflow: hidden;
}

.card:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    transition: 0.35s;
}

.cbutton {
    vertical-align: middle !important;
    padding: 1.25rem;
    margin: auto;
}

.cbutton .co-button,
.cbutton {
    border: 2px solid #47a547;
    padding: 10px 20px;
    background-color: #ffffff;
    color: #47a547;
    font-weight: 700;
    border-radius: 5px;
}

.co-button:hover {
    border: 2px solid #ffffff;
    padding: 10px 20px;
    background-color: #47a547 !important;
    color: #ffffff;
    border-radius: 5px;
    font-weight: 700;
}

button.btn.btn-primary {
    border: 2px solid #47a547;
    padding: 10px 20px;
    background-color: #ffffff;
    color: #47a547;
    font-weight: 700;
    border-radius: 5px;
}

button.btn.btn-primary:hover {
    border: 2px solid #ffffff;
    padding: 10px 20px;
    background-color: #47a547;
    color: #ffffff;
    border-radius: 5px;
    font-weight: 700;
}

@media (min-width: 1200px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1140px;
        padding: 50px 0px;
    }
}

h2 {
    font-weight: 500;
    text-transform: uppercase;
    font-size: 36px;
    color: #333;
}

.card-body {
    border-radius: 4px;
    padding: 8%;
    box-shadow: 0 0 130px 0 rgb(62 57 107 / 12%), 0 0 20px 0 rgb(62 57 107 / 12%);
    background-color: #fff;
}

@media screen and (min-width: 1200px) {
    .form-group {
        margin: 30px 0px;
    }

    .horizontal-form label.form-label {
        width: 50%;
        text-align: left;
    }

    .horizontal-form .form-control {
        float: right;
        width: 50%;
    }
}

.jumbotron-text {
    color: #333;
    top: 20%;
    left: 15%;
    position: absolute;
    display: block;
}

.jumbotron-text p {
    font-family: Arial, sans-serif;
    font-size: 16px;
    display: block;
    line-height: 1.6;
}

.jumbotron-text h1 {
    font-size: 3vmax;
    font-family: BebasNeueBold, Arial, sans-serif;
    font-weight: 700;
    line-height: 1.1;
}

.form-jumbotron {
    background: url(/static/media/form.a6f3ad1d.jpg) no-repeat 100%;

    background-size: 60%;
    min-height: 110px;
    display: none;
}

.form-jumbotron h3 {
    color: #333;
    position: absolute;
    top: 25%;
    left: 25%;
}

/*Hamzas Changes*/
p.desc.pbor {
    padding-right: 10px;
}

.MuiGrid-spacing-xs-5 {
    margin: 0px !important;
}

.btn-primary {
    color: #fff;
    background-color: #47a547 !important;
    border-color: #47a547 !important;
    width: 100%;
    font-weight: 700 !important;
    padding: 10px 20px !important;
}

.btn-primary:hover {
    color: #fff;
    background-color: #47a547 !important;
    border-color: #47a547 !important;
    width: 100%;

}

.wd.card {
    width: 100% !important;
}

.form-container {
    box-shadow: 0 0 130px 0 rgb(62 57 107 / 12%), 0 0 20px 0 rgb(62 57 107 / 12%) !important;
    padding: 50px;
    border-radius: 25px;
}

.form-container-outRow {
    padding: 30px 0px;
}

.course-details-p {
    white-space: break-spaces;
}

.next-Button-form {
    color: #fff !important;
    background-color: #47a547 !important;
    border-color: #47a547 !important;
    width: 300px !important;

}

.back-Button-form {
    color: black !important;
    background-color: white !important;
    border-color: black !important;
    width: 200px !important;
}

.back-Button-form:hover {
    -webkit-transform: scale(1.03);
            transform: scale(1.03);
    transition: 0.2s;
    color: black !important;
    background-color: white !important;
    border-color: black !important;
    width: 200px !important;
}

.next-Button-form:hover {
    -webkit-transform: scale(1.03);
            transform: scale(1.03);
    transition: 0.2s;
}

.buttons-form {
    margin-top: 20px;
}

.Error-msg-form {
    color: white;
    background-color: #ea2f2f !important;
    padding-top: 15px !important;
    padding: 5px;
}

.Error-msg-form-displayNone {
    display: none;
}

.Error-msg-form-display {
    display: block !important;
}

.landing-hero {
    height: 70vh;
}

.landing-page-btn {
    border-radius: 30px;
    background-color: #DE36AE;
    border-radius: 15px;
}

.landing-page-clipped {
    min-height: 350px;
    background-image: url(https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80);
    background-position: center;
    background-size: cover;
}

body {
    font-family: "Poppins", sans-serif !important;
}

.col-md-7.mx-auto.mb-7.mb-sm-0.headline {
    text-align: center;
}

.clipped {
    -webkit-clip-path: polygon(50% 0, 100% 0, 100% 100%, 0% 100%);
            clip-path: polygon(50% 0, 100% 0, 100% 100%, 0% 100%);
}

p.text-secondary.Subheadline {
    font-size: 19px;
    text-align: center;
    color: #292727 !important;
    font-weight: 500;
}

.landing-page-heading {
    font-size: 44px;
}

.w-100 {
    width: 100% !important;
    max-width: 1280px;
    margin: auto;
}

.container {
    max-width: 1320px;
}

section {
    overflow: hidden;
}

.section-bg {
    background: #f5f8fd;
}

.section-header h3 {
    font-size: 36px;
    color: #413e66;
    text-align: center;
    font-weight: 700;
    position: relative;
    font-family: "Montserrat", sans-serif;
}

.section-header p {
    text-align: center;
    margin: auto;
    font-size: 15px;
    padding-bottom: 60px;
    color: #535074;
    width: 50%;
}

.innerCardCollege {
    box-shadow: none !important;
}

.editCOllege input {
    float: right;
}

.editCOllege {
    padding: 50px 0px;
    width: 600px !important;
}

.studentLIst {
    width: 100% !important;
    max-width: 90%;
    margin: auto;
}

.collegePaeg {
    padding: 50px 0px;
}

@media (max-width: 767px) {
    .section-header p {
        width: 100%;
    }
}

#services {
    padding: 60px 0 40px 0;
}

#partners {
    background-color: white;
}

#services .box {
    padding: 30px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    margin: 0 10px 40px 10px;
    background: #fff;
    height: 250px;
    box-shadow: 0 10px 29px 0 rgba(68, 88, 144, 0.1);
    transition: all 0.3s ease-in-out;
    text-align: center;
}

#services .box:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

#services .icon {
    margin: 0 auto 15px auto;
    padding-top: 12px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    width: 60px;
    height: 60px;
}

#services .icon .service-icon {
    font-size: 36px;
    line-height: 1;
}

#services .title {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 18px;
}

.title-lp {
    color: #000 !important;
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 18px;
}

#services .title a {
    color: #111;
}

#services .box:hover .title a {
    color: #9b5de5;
}

#services .box:hover .title a:hover {
    text-decoration: none;
}

#services .description {
    font-size: 14px;
    line-height: 28px;
    margin-bottom: 0;
    text-align: center;
}

.course-details-p-ul li:nth-child(1) {
    display: none;
}

.course-details-p-ul2 li:nth-child(1) {
    list-style: none;
}

.course-details-p-ul2 {
    padding-left: 20px !important;
    padding-top: 15px !important;
}

.course-details-p-ul {
    padding-left: 20px !important;
}

div#uncontrolled-tab-example-tabpane-benefits {
    padding-top: 15px;
}

.col-md-12 {
    height: 100%;
}

#file-upload-laststep {
    padding: 30px;
    border: 3px dashed #eaeaea;
}

.file-upload-box {
    padding: 30px !important;
    border: 3px dashed #eaeaea;
}

.splash {
    background: url("https://res.cloudinary.com/my-free-course/image/upload/c_fit,h_300,w_900/v1/Site-pics/diverse-group-students-taking-online-tests_1_wom2vx.jpg") bottom center no-repeat;
    background-size: cover;
    color: white;
    overflow: hidden;
    position: relative;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, .4);
    z-index: 9999;
    padding-top: 120px !important;
    min-height: 300px !important;
    height: 300px;
}

.splash:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #000;
    opacity: 0.7;
    z-index: -1;
}

.splash2 {
    background: url("https://res.cloudinary.com/my-free-course/image/upload/c_fit,h_800,w_800/v1626862478/Site-pics/happy-diverse-business-people-making-deal_ppy6hn.jpg") bottom center no-repeat;
    background-size: cover;
    color: white;
    overflow: hidden;
    position: relative;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, .4);
    z-index: 9999;
    padding-top: 60px !important;
    padding-top: 60px !important;
    min-height: 100vh !important;
    /* height:100vh; */
    margin-bottom: 0px !important;
    border-radius: 0px !important;
}

.splash2:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #000;
    opacity: 0.7;
    z-index: -1;
}

.heading-jumbotron {
    width: 600px;
    margin: auto;
}

.heading-jumbotron-p {
    color: white;
    font-weight: 800;
}

.jumbotron p {
    font-weight: 400;
}

.only-on-desktop {
    display: block !important;
}

.only-on-mobile {
    display: none !important;
}

.nav-tabs .nav-link {
    margin-bottom: -1px;
    border: 1px solid #b9b9b9;
    border-bottom: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    padding: 15px !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #ffffff;
    background-color: #47a547;
    border-color: #4a4a4a;
    padding: 15px !important;
}

.form-container {
    margin: 0px !important;
}

.form-container-outRow {
    max-width: 1300px !important;
}

.form-group {
    padding: 10px 0px;
}

.form-notice {
    padding: 25px !important;
    margin-bottom: 20px !important;
    border: 1px solid transparent;
    border-radius: 4px;
    color: #000;
    background-color: #fcf8e3;
    border-color: #faebcc;
}

a.px-5.py-3.text-white.mt-3.mt-sm-0.landing-page-btn {
    cursor: pointer;
}

a.nav-link {
    padding-left: 30px !important;
}

.col {
    margin: auto;
}

.social-logo-section {
    margin: auto !important;
    width: 1280px;
    padding: 30px 0px;
}

.social-logo-section1 {
    margin: auto !important;
    width: 1280px;
    /* padding: 30px 0px; */
    display: flex;
}

.col-living-tabs {
    padding: 30px !important;
}

button.button.button-group-download.btn.btn-primary {
    color: white;
    width: 45%;
    margin-bottom: 10px;
    margin-right: 10px;
}

.spinner-border {
    width: 4rem !important;
    height: 4rem !important;
    color: white;
    margin-top: 40vh;
}

tr.approved {
    background: #28a74591;
}

#spinner-finalstep {
    height: 100%;
    top: 0;
    left: 0;
    width: 100%;
    position: fixed;
    background-color: #000000b3;
    z-index: 999;
    margin: 0px !important;
    max-height: 100%;
    max-width: 100%;
    overflow: hidden;
}

button#selected-active-row:active {
    background-color: #47a547;
    color: white;
}

.incomplete-container {
    max-width: 1600px !important;
}

.p-light {
    color: #707070;
}

#multi-select-contact ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

#multi-select-contact li {
    float: left;
    display: block;
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    margin: 2px;
    border-radius: 5px;
}

li.contact-title-day {
    margin-top: 5px;
    min-width: 120px;
    margin-bottom: 5px;
}

/* English test */
.english-assessment-long div {
    padding: 10px;
}

.badge-info {
    color: #fff;
    background-color: #17a2b8;
    padding: 5px;
    margin-bottom: 5px;
}

span.approved-count-table {
    color: #007bff;
    font-size: 14px;
}

.policy {
    overflow-wrap: anywhere;
}

img#logo-europe {
    float: right;
    width: 80px;
}

.PrivateSwitchBase-input-17 {
    opacity: 1 !important;
}

/* English test end */
@media only screen and (max-width: 600px) {
    #file-upload-laststep {
        padding: 10px;
        border: 3px dashed #eaeaea;
    }
    .file-upload-box {
        padding: 10px !important;
        border: 3px dashed #eaeaea;
    }

    #multi-select-contact ul {
        margin: 5px 0px;
        display: table-cell;
        overflow: auto;
    }

    div#multi-select-contact {
        overflow: auto;
    }

    #multi-select-contact li {
        float: inherit;
        text-align: center;
    }

    .only-on-desktop {
        display: none !important;
    }

    .only-on-mobile {
        display: block !important;
    }

    .col-living-tabs {
        padding: 0px;
    }

    #learning {
        padding-top: 50px;
        text-align: center;
    }

    .social-logo-section1 {
        display: none !important;
    }

    .splash {
        padding-top: 80px !important;
    }

    .row {
        margin: 0px !important;
    }

    .form-container {
        box-shadow: 0 0 130px 0 rgb(62 57 107 / 12%), 0 0 20px 0 rgb(62 57 107 / 12%) !important;
        padding: 35px 15px;
        border-radius: 15px;
    }

    .MuiContainer-root {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .card {
        padding: 25px 10px !important;
    }

    h5.card-title {
        font-weight: bold;
        font-size: 18px;
        margin-top: 20px;
    }

    .container-sm.course-details-p {
        padding-top: 50px;
    }

    .info-box-courses {
        padding: 0px;
    }

    .form-container-outRow h1 {
        font-size: 24px;
        font-weight: 700;
    }

    .next-Button-form {
        margin-top: 25px !important;
        width: 100% !important;

    }

    .back-Button-form {
        margin-top: 25px !important;
        width: 100% !important;
    }

    .back-Button-form:hover {
        width: 100% !important;
    }

    .d-sm-flex.align-items-center.justify-content-between.w-100.landing-hero {
        margin-top: 0px;
    }

    .landing-page-heading {
        font-size: 24px !important;
        text-align: center;
    }

    .landing-hero {
        height: 80%;
    }

    .header-logo {
        text-align: center;
        margin: auto;
    }

    .navbar-nav {
        padding-left: 0px;
    }

    .Subheadline {
        text-align: center !important;
    }

    .headline {
        left: 0px;
    }

    .clipped {
        -webkit-clip-path: none;
                clip-path: none;
        max-height: 300px !important;
    }

    .landing-hero {
        text-align: center;
    }

    .ml-auto,
    .mx-auto {
        margin-left: auto !important;
        padding: 50px 0px;
    }

    .d-sm-flex.align-items-center.justify-content-between.w-100.landing-hero {
        flex-wrap: wrap-reverse !important;
        display: flex;
    }

    .col-md-6.col-lg-2 {
        text-align: center;

    }

    .form-container-outRow .nav-item {
        padding-right: 18px;
    }

    .col-md-7.mx-auto.mb-7.mb-sm-0.headline {
        text-align: center;
        padding: 0px 20px 40px 20px;
    }

    a.mobile-menu-sub {
        padding: 10px !important;
        font-size: 18px !important;
    }

    .ml-auto,
    .mx-auto {
        padding-bottom: 0px !important;
        padding-top: 0px !important;
    }

    h2.display-4.my-4.font-weight-bold.landing-page-heading {
        margin: 0px !important;
        font-size: 30px !important;
    }

    p.text-secondary.Subheadline {
        font-size: 14px !important;
    }

    .row.social-logo-section.only-on-mobile img {
        /* width: 150px; */
        /* padding: 10px; */
    }

    .mobile-nav-s a.nav-link {
        padding: 3px !important;
        font-size: 14px;
        display: none;
    }

    .jumbotron.text-center.splash.top-personal {
        margin-bottom: 0px !important;
        padding: 80px 10px !important;
        min-height: 100px !important;
        height: 230px;
    }

    .text-left.mt-5.mb-3.form-notice.top-personal-box {
        margin: 0px !important;
    }

    .copyright {
        text-align-last: center;
    }

    .footer-text {
        text-align: center;
    }
}
:root {
    --primary: #de37ae;
    --secondary: #ff7978;
    --tertiary: #4cd9c8;
    --dark: #333333;
    --light: #ffffff;
    --gray: #f8f9fa;
    --gray-dark: #6c757d;
}

body {
    font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
        "Helvetica Neue", sans-serif;
    color: #333333;
    color: var(--dark);
    line-height: 1.6;
}

/* Custom Bootstrap Overrides */
.btn-primary {
    background-color: #de37ae;
    background-color: var(--primary);
    border-color: #de37ae;
    border-color: var(--primary);
}

.btn-primary:hover,
.btn-primary:focus {
    background-color: #c52e99;
    border-color: #c52e99;
}

.btn-outline-primary {
    color: #de37ae;
    color: var(--primary);
    border-color: #de37ae;
    border-color: var(--primary);
}

.btn-outline-primary:hover,
.btn-outline-primary:focus {
    background-color: #de37ae;
    background-color: var(--primary);
    border-color: #de37ae;
    border-color: var(--primary);
}

.btn-secondary {
    background-color: #ff7978;
    background-color: var(--secondary);
    border-color: #ff7978;
    border-color: var(--secondary);
}

.btn-outline-secondary {
    color: #ff7978;
    color: var(--secondary);
    border-color: #ff7978;
    border-color: var(--secondary);
}

.btn-outline-secondary:hover,
.btn-outline-secondary:focus {
    background-color: #ff7978;
    background-color: var(--secondary);
    border-color: #ff7978;
    border-color: var(--secondary);
}

.btn-tertiary {
    background-color: #4cd9c8;
    background-color: var(--tertiary);
    border-color: #4cd9c8;
    border-color: var(--tertiary);
}

.btn-outline-tertiary {
    color: #4cd9c8;
    color: var(--tertiary);
    border-color: #4cd9c8;
    border-color: var(--tertiary);
}

.btn-outline-tertiary:hover,
.btn-outline-tertiary:focus {
    background-color: #4cd9c8;
    background-color: var(--tertiary);
    border-color: #4cd9c8;
    border-color: var(--tertiary);
}

.bg-primary {
    background-color: #de37ae !important;
    background-color: var(--primary) !important;
}

.bg-secondary {
    background-color: #ff7978 !important;
    background-color: var(--secondary) !important;
}

.bg-tertiary {
    background-color: #4cd9c8 !important;
    background-color: var(--tertiary) !important;
}

/* Navbar */
.navbar-custom {
    background-color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.navbar-custom .navbar-brand {
    color: #de37ae;
    color: var(--primary);
}

.navbar-custom .nav-link {
    font-weight: 500;
    transition: color 0.3s ease;
}

.navbar-custom .nav-link:hover {
    color: #de37ae;
    color: var(--primary);
}

/* Hero Section */
.hero-section {
    background-color: #f9f0f6;
    padding-top: 6rem;
    padding-bottom: 6rem;
}

/* Why Choose Us Section */
.why-choose-us {
    background-color: white;
}

.why-choose-card {
    border-radius: 10px;
    border: none;
    /* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05); */
    transition: box-shadow 0.3s ease, -webkit-transform 0.3s ease;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    transition: transform 0.3s ease, box-shadow 0.3s ease, -webkit-transform 0.3s ease;
}

.why-choose-card.card {
    border: none !important;
    padding: 0px !important;
}

.card-body {
    width: 100% !important;
}

.why-choose-card:hover {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}

.why-choose-card p.card-text {
    background-color: white !important; 
    width: 100% !important;
}

.icon-circle {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
}

.cta-box {
    background-color: #f9f0f6;
    border-radius: 10px;
    padding: 2rem;
    margin-top: 3rem;
}

/* Funding Section */
.funding-section {
    background-color: #f0f9f8;
}

/* Courses Section */
.courses-section {
    background-color: white;
}

.course-card {
    border-radius: 10px;
    border: none;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
    overflow: hidden;
    position: relative;
}

.course-card:hover {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
}

.bg-white {
    background: white;
}

.course-tag {
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 0.8rem;
    font-weight: 600;
    z-index: 1;
}

/* FAQs Section */
.faqs-section {
    background-color: #f9f0f6;
}

.custom-accordion .accordion-button:not(.collapsed) {
    background-color: #f9f0f6;
    color: #de37ae;
    color: var(--primary);
}

.custom-accordion .accordion-button:focus {
    box-shadow: 0 0 0 0.25rem rgba(222, 55, 174, 0.25);
}

/* Contact Section */
.contact-section {
    background-color: white;
}

.contact-card {
    background-color: #bbebe5;
    border-radius: 10px;
    padding: 1rem 2rem;
    position: relative;
    overflow: hidden;
}

.contact-decoration {
    position: absolute;
    right: -50px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 200px;
    height: 200px;
    background: linear-gradient(135deg, #4cd9c8, #ff7978);
    background: linear-gradient(135deg, var(--tertiary), var(--secondary));
    border-radius: 50%;
    opacity: 0.2;
}

/* Footer */
.footer {
    background-color: #333;
    color: white;
}

.footer a {
    color: #ddd;
    margin: 0 10px;
}

.footer a:hover {
    color: white;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .hero-section {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    .contact-card {
        padding: 1.5rem;
    }
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}
.success-box{
    background-color: white !important;
    
    border-top: 5px #DE37AE solid;
    border-radius: 10px;
    color: black;
    text-shadow: none;
  }
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

