:root {
    --primary: #de37ae;
    --secondary: #ff7978;
    --tertiary: #4cd9c8;
    --dark: #333333;
    --light: #ffffff;
    --gray: #f8f9fa;
    --gray-dark: #6c757d;
}

body {
    font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
        "Helvetica Neue", sans-serif;
    color: var(--dark);
    line-height: 1.6;
}

/* Custom Bootstrap Overrides */
.btn-primary {
    background-color: var(--primary);
    border-color: var(--primary);
}

.btn-primary:hover,
.btn-primary:focus {
    background-color: #c52e99;
    border-color: #c52e99;
}

.btn-outline-primary {
    color: var(--primary);
    border-color: var(--primary);
}

.btn-outline-primary:hover,
.btn-outline-primary:focus {
    background-color: var(--primary);
    border-color: var(--primary);
}

.btn-secondary {
    background-color: var(--secondary);
    border-color: var(--secondary);
}

.btn-outline-secondary {
    color: var(--secondary);
    border-color: var(--secondary);
}

.btn-outline-secondary:hover,
.btn-outline-secondary:focus {
    background-color: var(--secondary);
    border-color: var(--secondary);
}

.btn-tertiary {
    background-color: var(--tertiary);
    border-color: var(--tertiary);
}

.btn-outline-tertiary {
    color: var(--tertiary);
    border-color: var(--tertiary);
}

.btn-outline-tertiary:hover,
.btn-outline-tertiary:focus {
    background-color: var(--tertiary);
    border-color: var(--tertiary);
}

.bg-primary {
    background-color: var(--primary) !important;
}

.bg-secondary {
    background-color: var(--secondary) !important;
}

.bg-tertiary {
    background-color: var(--tertiary) !important;
}

/* Navbar */
.navbar-custom {
    background-color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.navbar-custom .navbar-brand {
    color: var(--primary);
}

.navbar-custom .nav-link {
    font-weight: 500;
    transition: color 0.3s ease;
}

.navbar-custom .nav-link:hover {
    color: var(--primary);
}

/* Hero Section */
.hero-section {
    background-color: #f9f0f6;
    padding-top: 6rem;
    padding-bottom: 6rem;
}

/* Why Choose Us Section */
.why-choose-us {
    background-color: white;
}

.why-choose-card {
    border-radius: 10px;
    border: none;
    /* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05); */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.why-choose-card.card {
    border: none !important;
    padding: 0px !important;
}

.card-body {
    width: 100% !important;
}

.why-choose-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}

.why-choose-card p.card-text {
    background-color: white !important; 
    width: 100% !important;
}

.icon-circle {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
}

.cta-box {
    background-color: #f9f0f6;
    border-radius: 10px;
    padding: 2rem;
    margin-top: 3rem;
}

/* Funding Section */
.funding-section {
    background-color: #f0f9f8;
}

/* Courses Section */
.courses-section {
    background-color: white;
}

.course-card {
    border-radius: 10px;
    border: none;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease;
    overflow: hidden;
    position: relative;
}

.course-card:hover {
    transform: translateY(-5px);
}

.bg-white {
    background: white;
}

.course-tag {
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 0.8rem;
    font-weight: 600;
    z-index: 1;
}

/* FAQs Section */
.faqs-section {
    background-color: #f9f0f6;
}

.custom-accordion .accordion-button:not(.collapsed) {
    background-color: #f9f0f6;
    color: var(--primary);
}

.custom-accordion .accordion-button:focus {
    box-shadow: 0 0 0 0.25rem rgba(222, 55, 174, 0.25);
}

/* Contact Section */
.contact-section {
    background-color: white;
}

.contact-card {
    background-color: #bbebe5;
    border-radius: 10px;
    padding: 1rem 2rem;
    position: relative;
    overflow: hidden;
}

.contact-decoration {
    position: absolute;
    right: -50px;
    top: 50%;
    transform: translateY(-50%);
    width: 200px;
    height: 200px;
    background: linear-gradient(135deg, var(--tertiary), var(--secondary));
    border-radius: 50%;
    opacity: 0.2;
}

/* Footer */
.footer {
    background-color: #333;
    color: white;
}

.footer a {
    color: #ddd;
    margin: 0 10px;
}

.footer a:hover {
    color: white;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .hero-section {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    .contact-card {
        padding: 1.5rem;
    }
}